import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

// import { useWhyDidYouUpdate } from "@shared/hooks/useWhyDidYouUpdate";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";
import { useModalContext } from "@shared/context/ModalContext";
import { getTimestamp } from "@shared/queryHooks/useOrganization";
import { useJobs, useAllJobs } from "@shared/queryHooks/useJob";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";
import Banner from "@ats/src/components/shared/Banner";
import NavBar from "@shared/components/NavBar";
import EmptyState from "@ats/src/components/shared/EmptyState";
import Button from "@ats/src/components/shared/Button";
import JobListItem from "@ats/src/views/jobs/components/JobListItem";
import NewJobCenterModal from "@ats/src/components/modals/NewJobCenterModal";
import JobListFilter from "@ats/src/views/jobs/components/JobListFilter";
import Icon from "@ats/src/components/shared/Icon";
import { useFeatureFlipper, Features } from "@ats/src/components/shared/FeatureFlipper";
import { isDeadlineDateAfterCurrent } from "@shared/lib/time";

function JobList(props) {
  // useWhyDidYouUpdate("JobList", props);
  const { history, meta, match, location } = props;
  const hasStatus = meta?.status != undefined;
  const { openModal, removeModal } = useModalContext();
  const { currentUser, currentOrganizationUser, currentOrganization } = useCurrentSession();
  const webflowUpdatesInfoBannerEnabled = useFeatureFlipper()({ feature: Features.WEBFLOW_UPDATE_ALERTS });
  const hadAnActiveSubscriptionInThePast =
    !currentOrganization.stripeSubscriptionInGoodStanding &&
    currentOrganization.publishedJobsCount > 0;
  const shouldDisplayWebflowUpdatesBanner =
    webflowUpdatesInfoBannerEnabled &&
    currentOrganization?.stripeSubscriptionInGoodStanding &&
    currentOrganization?.hasWebflowAuthentication &&
    !currentOrganization?.webflowAuthenticatedViaApiV2;

  window.logger("%c[JobList] RENDER ", "color: #1976D2", { currentUser, currentOrganization });

  const {
    data: jobsData,
    refetch: refetchJobs,
    isLoading: isLoadingJobs,
    isFetching: isFetchingJobs,
  } = useJobs({
    refetchOnWindowFocus: false,
    status: meta?.status,
    enabled: meta?.adminOnly == undefined,
  });

  const {
    data: allJobsData,
    refetch: refetchAllJobs,
    isLoading: isLoadingAllJobs,
    isFetching: isFetchingAllJobs,
  } = useAllJobs({
    refetchOnWindowFocus: false,
    status: meta?.status,
    enabled: meta?.adminOnly != undefined,
  });

  const jobs =
    (match.path === "/jobs/all" || match.path === "/jobs/archived") && allJobsData != undefined
      ? allJobsData.items
      : match.path === "/jobs" && jobsData != undefined
        ? jobsData.items
        : [];

  // const careersPage =
  //   currentOrganization?.careersPages != undefined ? currentOrganization?.careersPages[0] : null;
  // const slug = careersPage?.slug;

  // window.logger("\n\n\n%c[JobList] render", "background-color: #FFF000", {
  //   meta,
  //   jobsData,
  //   allJobsData,
  //   hasStatus,
  //   jobs,
  //   isLoadingJobs,
  //   isLoadingAllJobs,
  //   isFetchingJobs,
  //   isFetchingAllJobs,
  // });

  React.useEffect(() => {
    // window.logger("%c[JobList] useEffect", "background-color: #46b942", {
    //   ...meta,
    //   jobsData,
    //   allJobsData,
    //   hasStatus,
    // });

    if (meta && meta.adminOnly) {
      window.logger("%c[JobList] Refetch ALL Jobs", "background-color: #47967b");
      refetchAllJobs();
    } else {
      window.logger("%c[JobList] Refetch Regular Jobs", "background-color: #038b85");
      refetchJobs();
    }
  }, [hasStatus, meta, refetchAllJobs, refetchJobs]);

  const buildItemNodes = () => {
    const emptyContent = {
      title: "",
      message: "",
    };

    switch (match.path) {
      case "/jobs/archived":
        emptyContent.title = "There are no archived jobs";
        emptyContent.message =
          "When you archive a job, it will appear here so you can still access it.";
        break;
      case "/jobs/all":
        emptyContent.title = "There are no active jobs";
        emptyContent.message = "You can create a new job using the link on the right.";
        break;
      default:
        emptyContent.title = currentOrganizationUser.isAdmin
          ? "You have no assigned jobs"
          : "There are no jobs assigned to you";
        emptyContent.message = currentOrganizationUser.isAdmin
          ? "When you are on a job's hiring team, it will appear here. View all active jobs using the dropdown on the left."
          : "To have access to a job, an account admin must add you to its hiring team.";
    }

    if (jobs.length > 0) {
      return jobs.map(function (job) {
        return (
          <JobListItem
            // jobId={job.id}
            job={job}
            key={job.id} // try to avoid using index as the key
            history={history}
            location={location}
            currentOrganization={currentOrganization}
            currentUser={currentUser}
          />
        );
      });
    } else {
      return (
        <EmptyState icon="maximize" title={emptyContent.title} message={emptyContent.message} />
      );
    }
  };

  const handleClickCreateNewJob = (e) => {
    e.preventDefault();
    e.stopPropagation();

    window.dataLayer.push({
      event: "ctaClick",
      ctaLocation: "Job List View",
      ctaLabel: "Create new job",
      transactionId: Date.now().toString(),
      transactionValue: "0",
      userData: {
        email: currentUser.email,
      },
    });

    const modal = <NewJobCenterModal history={history} onCancel={() => removeModal()} />;

    openModal(modal);
  };

  const createJobButton = () => {
    return (
      currentOrganizationUser.isAdmin && (
        <Styled.CreateJobButtonContainer>
          <Button
            styleType="secondary"
            onClick={handleClickCreateNewJob}
            data-testid="create-job-button"
            trackingPayload={{ ctaLocation: "Job List View" }}
          >
            Create new job
          </Button>

          <button onClick={handleClickCreateNewJob} data-testid="create-job-button">
            <Icon name="plus" />
          </button>
        </Styled.CreateJobButtonContainer>
      )
    );
  };

  const handleClickOnViewPreview = () => {
    // const slug = currentOrganization.careersPages[0].slug;
    getTimestamp().then((data) => {
      window.open(`${currentOrganization?.careersPageUrl}/preview/${data.timestamp}`, "_blank");
    });
  };

  const createNavLabel = (title, path) => {
    return <JobListFilter title={title} path={path} />;
  };

  const getWebflowBannerMessage = () => {
    const isAfterDeadline = isDeadlineDateAfterCurrent("03-17-2025");
    const bannerLinkToWebflow = {
      pathname: `/hire/settings/integrations/webflow`,
      state: { orginalPathname: location.pathname },
    };
    let message;
    if (isAfterDeadline) {
      message = (
        <span>
          <b>Your jobs are not syncing with Webflow.</b> To resume syncing, reauthenticate your Webflow account in {" "}
          {<Link to={bannerLinkToWebflow}><b>integration settings.</b></Link>}
        </span>
      );
    } else {
      message = (
        <span>
          Webflow integrations must be reauthenticated by <b>March 17, 2025</b> or job data will no longer sync. Visit{" "}
          {<Link to={bannerLinkToWebflow}><b>integration settings</b></Link>} to reauthenticate.
        </span>
      );
    }
    return message;
  };

  const getBanner = () => {
    const bannerLinkToContent = {
      pathname: `/hire/settings/billing`,
      state: { orginalPathname: location.pathname },
    };

    if (hadAnActiveSubscriptionInThePast) {
      return (
        <Banner
          message={
            <span>
              Published job posts are not visible without an active subscription. Visit{" "}
              {<Link to={bannerLinkToContent}>plan & billing</Link>} to find out more.
            </span>
          }
        />
      );
    } else if (currentOrganization.stripeSubscriptionStatus === "past_due") {
      return (
        <Banner
          message={
            <span>
              The payment for your subscription is <b>past due</b>. Visit{" "}
              {<Link to={bannerLinkToContent}>plan & billing</Link>} to update your payment method.
            </span>
          }
        />
      );
    } else if (shouldDisplayWebflowUpdatesBanner) {
      return <Banner message={getWebflowBannerMessage()}/>
    }
  };



  // window.logger("%c[JobList] render", "background-color: #FFF000", { props });
  let itemNodes = buildItemNodes();

  const content = {
    page: "",
    count: "",
  };

  switch (match.path) {
    case "/jobs/archived":
      content.page = "Archived jobs";
      content.count = `There ${itemNodes.length === 1 ? "is" : "are"} ${
        itemNodes.length || "no"
      } archived job${itemNodes.length > 1 || !itemNodes.length ? "s" : ""}`;
      break;
    case "/jobs/all":
      content.page = "All active jobs";
      content.count = `There ${itemNodes.length === 1 ? "is" : "are"} ${
        itemNodes.length || "no"
      } active job${itemNodes.length > 1 || !itemNodes.length ? "s" : ""}`;
      break;
    default:
      content.page = "Your assigned jobs";
      content.count = `You have ${itemNodes.length || "no"} job assignment${
        itemNodes.length > 1 || !itemNodes.length ? "s" : ""
      }`;
  }

  return (
    <>
      <Helmet title="Jobs" />
      {getBanner()}
      <NavBar
        title={createNavLabel(content.page, match.path)}
        secondary={createJobButton()}
        isBack={false}
        history={null}
      />
      {/* <NavBar
        title={createNavLabel(content.page, match.path)}
        isBack={false}
        history={null}
      /> */}
      {isLoadingAllJobs || isLoadingJobs || currentOrganization.careersPages == undefined ? (
        <LoadingIndicator label="Loading..." />
      ) : (
        <Styled.Container>
          <Styled.JobList>
            <Styled.Header data-testid="joblist-header">
              <Styled.Intro>
                {content.count}
                {currentOrganization.stripeSubscriptionInGoodStanding ||
                hadAnActiveSubscriptionInThePast ? (
                  <Link
                    to={`${currentOrganization?.careersPageUrl}`}
                    target="_blank"
                    rel="noopener"
                    data-testid="job-board-link"
                  >
                    View job board
                    <Icon name="external-link" />
                  </Link>
                ) : (
                  <Button styleType="text" onClick={handleClickOnViewPreview}>
                    Preview job board
                    <Icon name="external-link" />
                  </Button>
                )}
              </Styled.Intro>
            </Styled.Header>
            {itemNodes}
          </Styled.JobList>
        </Styled.Container>
      )}
    </>
  );
}

export default JobList;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Container = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobList;
    ${[t.px(4), t.py(4)]}
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;

    ${t.mq["lg"]} {
      ${t.pt(8)}
    }
  `;
});

Styled.CreateJobButtonContainer = styled.div((props: any) => {
  const t: any = props.theme;
  return css`
    label: JobList_Header;
    button:first-of-type {
      display: none;
    }
    button:last-of-type {
      ${[t.text.base, t.h(10), t.w(10), t.mr(-3)]}
      color: ${t.dark ? t.color.gray[200] : t.color.black};
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      t.text.h5
    }

    ${t.mq["md"]} {
      button:first-of-type {
        display: flex;
      }
      button:last-of-type {
        display: none;
      }
    }
  `;
});

Styled.JobList = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobList_Jobs;
    width: 100%;
    max-width: 992px;
  `;
});

Styled.Header = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobList_Header;
    ${[t.mb(4)]}
    justify-content: space-between;
    align-items: center;
    display: none;

    ${t.mq["lg"]} {
      display: flex;
    }
  `;
});

Styled.Intro = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobList_Header_Intro;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    display: flex;

    h1 {
      ${[t.text.h5]};
      text-transform: capitalize;
    }
    a,
    button {
      ${[t.ml(2), t.text.medium]}
      color: ${t.dark ? t.color.gray[300] : t.color.black};
      display: flex;
      align-items: center;

      svg {
        margin-left: 0.375rem;
        height: 1.125em;
        width: 1.125em;
      }
    }
    a:hover {
      text-decoration: underline;
    }
  `;
});

Styled.Footer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: JobList_Footer;
    ${[t.mt(20), t.text.xs]}
    color: ${t.color.gray[600]};
    width: 100%;
    display: flex;
    justify-content: space-between;
    a:hover {
      text-decoration: underline;
    }
  `;
});
