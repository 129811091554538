import React, { ReactFragment } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";
import OauthConnectButton from "@shared/components/OauthConnectButton";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  path: string;
  buttonLabel: string;
  title: string;
  subcopy?: string | ReactFragment;
  onCancel: () => void;
};

function OauthReconnectModal(props: Props) {
  const { title, path, buttonLabel, subcopy, onCancel } = props;

  const { dismissModalWithAnimation } = useModalContext();

  return (
    <CenterModal headerTitleText={title} onCancel={onCancel}>
      {subcopy && <Styled.Instructions>{subcopy}</Styled.Instructions>}
      <Styled.ButtonContainer>
        <div>
          <OauthConnectButton path={path} label={buttonLabel} />
        </div>
        <div>
          <Button
            styleType="secondary"
            className="OauthReconnectModal-cancel"
            onClick={() => dismissModalWithAnimation(() => onCancel)}
          >
            Cancel
          </Button>
        </div>
      </Styled.ButtonContainer>
    </CenterModal>
  );
}

export default OauthReconnectModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OauthReconnectModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: OauthReconnectModal_ButtonContainer;
    display: flex;
    
    button {
      ${t.mt(0)}
    }

    > * {
      ${[t.mr(3), t.mt(0)]}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});
