import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiGet, apiPut, apiPost } from "@shared/queryHooks/api";

/* API requests
--===================================================-- */
const getWebflowSites = async () => {
  return await apiGet({ path: "/integrations/webflow_integrations/sites" });
};

const getWebflowCollections = async () => {
  return await apiGet({ path: "/integrations/webflow_integrations/collections" });
};

const getWebflowCollection = async ({ collectionId }) => {
  if (collectionId != undefined) {
    return await apiGet({ path: `/integrations/webflow_integrations/collections/${collectionId}` });
  }
};

const createWebflowIntegration = async ({
  siteId,
  siteName,
  sitePreviewUrl,
  collectionId,
  collectionName,
  fieldMapping,
}) => {
  window.logger("%c[useWebflow] createWebflowIntegration\n\n\n\n", "background-color: #FF76D2", {
    siteId,
    siteName,
    sitePreviewUrl,
    collectionId,
    collectionName,
    fieldMapping,
  });
  return await apiPost({
    path: `/integrations/webflow_integrations`,
    variables: { siteId, siteName, sitePreviewUrl, collectionId, collectionName, fieldMapping },
  });
};

const updateWebflowIntegration = async ({
  webflowIntegrationId, // currently just a placeholder
  siteId,
  siteName,
  sitePreviewUrl,
  collectionId,
  collectionName,
  fieldMapping,
}: {
  webflowIntegrationId: any; // currently just a placeholder
  siteId?: string;
  siteName?: string;
  sitePreviewUrl?: string;
  collectionId?: string;
  collectionName?: string;
  fieldMapping?: {};
}) => {
  window.logger("%c[useWebflow] updateWebflowIntegration\n\n\n\n", "background-color: #FF76D2", {
    siteId,
    siteName,
    sitePreviewUrl,
    collectionId,
    collectionName,
    fieldMapping,
  });
  return await apiPut({
    path: `/integrations/webflow_integrations/${webflowIntegrationId}`,
    variables: { siteId, siteName, sitePreviewUrl, collectionId, collectionName, fieldMapping },
  });
};

/* Hooks
--===================================================-- */
export function useWebflowSites({
  enabled = false,
  onError,
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery("webflowSites", getWebflowSites, {
    retry: false,
    enabled,
    onError
  });
}

export function useWebflowCollections({
  enabled = false,
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
  isFetching: boolean;
} {
  return useQuery("webflowCollections", getWebflowCollections, { retry: false, enabled });
}

export function useWebflowCollection({
  collectionId,
}): {
  status: any;
  data: any;
  error: any;
  isLoading: boolean;
} {
  return useQuery(["webflowCollection", collectionId], () =>
    getWebflowCollection({ collectionId }),
  );
}

export function useCreateWebflowIntegration() {
  const queryClient = useQueryClient();
  return useMutation(createWebflowIntegration, {
    onSuccess: (data, mutationVariables) => {
      window.logger("%c[useWebflow] useCreateWebflowIntegration onSuccess", "color: #1976D2", {
        data,
        mutationVariables,
      });
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("webflowSites");
      queryClient.invalidateQueries("webflowCollections");
      queryClient.invalidateQueries("webflowCollection");
      // queryClient.invalidateQueries(["jobs", data.jobId]);
      // queryClient.invalidateQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

export function useUpdateWebflowIntegration() {
  const queryClient = useQueryClient();
  return useMutation(updateWebflowIntegration, {
    onSuccess: (data, mutationVariables) => {
      // window.logger("%c[useWebflow] useUpdateWebflowIntegration onSuccess", "color: #1976D2", {
      //   data,
      //   mutationVariables,
      // });
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("webflowSites");
      queryClient.invalidateQueries("webflowCollections");
      queryClient.invalidateQueries("webflowCollection");
      // queryClient.invalidateQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

export function useUpdateSelectedSite() {
  const queryClient = useQueryClient();
  return useMutation(updateWebflowIntegration, {
    onSuccess: (data, mutationVariables) => {
      // window.logger("%c[useWebflow] useUpdateWebflowIntegration onSuccess", "color: #1976D2", {
      //   data,
      //   mutationVariables,
      // });
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("webflowCollections");
      queryClient.invalidateQueries("webflowCollection");
      // queryClient.invalidateQueries(["jobs", data.jobId]);
      // queryClient.invalidateQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

export function useUpdateSelectedCollection() {
  const queryClient = useQueryClient();
  return useMutation(updateWebflowIntegration, {
    onSuccess: (data, mutationVariables) => {
      // window.logger("%c[useWebflow] useUpdateWebflowIntegration onSuccess", "color: #1976D2", {
      //   data,
      //   mutationVariables,
      // });
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("currentOrganization");
      queryClient.invalidateQueries("webflowCollection");
      // queryClient.invalidateQueries(["jobs", data.jobId]);
      // queryClient.invalidateQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

export function useUpdateFieldMapping() {
  const queryClient = useQueryClient();
  return useMutation(updateWebflowIntegration, {
    onSuccess: (data, mutationVariables) => {
      // window.logger("%c[useWebflow] useUpdateWebflowIntegration onSuccess", "color: #1976D2", {
      //   data,
      //   mutationVariables,
      // });
      queryClient.invalidateQueries("me");
      queryClient.invalidateQueries("currentOrganization");
      // queryClient.invalidateQueries(["jobs", data.jobId]);
      // queryClient.invalidateQueries(["questions", data.jobId]);
    },

    // throwOnError: true,
  });
}

function useUpdateWebflowIntegrationOptimistic(question) {
  const queryClient = useQueryClient();
  return useMutation(updateWebflowIntegration, {
    // When mutate is called:
    onMutate: (newWebflowIntegration) => {
      window.logger(
        "%c[useWebflow] useUpdateWebflowIntegrationOptimistic onMutate",
        "color: #1976D2",
        {
          question,
          newWebflowIntegration,
        },
      );
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(["questions", newWebflowIntegration.jobId]);

      // Snapshot the previous value
      const previousWebflowIntegration = queryClient.getQueryData([
        "questions",
        newWebflowIntegration.jobId,
      ]);

      // Optimistically update to the new value (combining the full question with whatever the update is)
      queryClient.setQueryData(["questions", newWebflowIntegration.jobId], {
        ...question,
        ...newWebflowIntegration,
      });

      // Return a rollback function
      return () =>
        queryClient.setQueryData(
          ["questions", newWebflowIntegration.jobId],
          previousWebflowIntegration,
        );
    },

    // onSuccess: (data, mutationVariables) => {
    //   window.logger("%c[useWebflow] useUpdateWebflowIntegration onSuccess", "color: #1976D2", {
    //     data,
    //     mutationVariables,
    //   });
    //   queryClient.invalidateQueries(["questions", data.id]);
    // },

    // If the mutation fails, use the rollback function we returned above in onMutate
    onError: (err: any, newWebflowIntegration: any, rollback: any) => rollback(),
    // Always refetch after error or success:
    onSettled: (newWebflowIntegration) => {
      queryClient.invalidateQueries(["questions", newWebflowIntegration.jobId]);
    },

    // throwOnError: true,
  });
}
