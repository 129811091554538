import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { Link } from "react-router-dom";
import { removeHttpPrefix } from "@shared/lib/utils";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import FormSection from "@ats/src/components/forms/FormSection";
import FormInput from "@ats/src/components/forms/FormInput";
import Button from "@ats/src/components/shared/Button";
import Icon from "@ats/src/components/shared/Icon";
// import { FeatureFlipper } from "@ats/src/components/shared/FeatureFlipper";

import CustomDomainStatus from "@ats/src/views/accountAdmin/components/CustomDomainStatus";

import LoadingIndicator from "@ats/src/components/shared/LoadingIndicator";

import { useToastContext } from "@shared/context/ToastContext";
import { useCreateCustomDomain, useDeleteCustomDomain } from "@shared/queryHooks/useCareersPage";

function AccountJobBoardCustomDomain(props: any) {
  const addToast = useToastContext();

  const { currentOrganization } = props;

  const {
    mutate: createCustomDomain,
    isLoading: isLoadingCreateCustomDomain,
  } = useCreateCustomDomain();

  const {
    mutate: deleteCustomDomain,
    isLoading: isLoadingDeleteCustomDomain,
  } = useDeleteCustomDomain();

  const [careersPage, setCareersPage] = React.useState(currentOrganization?.careersPages[0]);

  const [errors, setErrors] = React.useState(null);

  window.logger("%c[AccountJobBoardCustomDomain] ", "background-color: #FF76D2", {
    currentOrganization,
    careersPage,
  });

  /* Update careersPage if currentOrganization changes
  --===================================================-- */
  React.useEffect(() => {
    setCareersPage(currentOrganization?.careersPages[0]);
  }, [currentOrganization]);

  if (careersPage == undefined) {
    return <LoadingIndicator label="Loading..." />;
  }

  const { customDomain } = careersPage;

  const onClickSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    createCustomDomain(
      {
        id: careersPage.id,
        organizationId: currentOrganization.id,
        customDomain,
      },
      {
        onSuccess: () => {
          props.setIsDirty(false);
          addToast({ title: "Job board settings saved", kind: "success" });
        },
        onError: (data) => {
          setErrors((data as any).errors);
          addToast({
            title: `Could not save settings`,
            kind: "danger",
          });
          // onError(data);
          window.logger(
            "%c[AccountJobBoardCustomDomain] updateCareersPage onerror",
            "background-color: #eb7f37",
            { data },
          );
        },
      },
    );
  };

  const handleDeleteCustomDomain = (e) => {
    if (e) e.preventDefault();

    deleteCustomDomain(
      { id: careersPage.id, organizationId: currentOrganization.id },
      {
        onSuccess: () => {
          props.setIsDirty(false);
          addToast({ title: "Job board settings saved", kind: "success" });
        },
        onError: (data) => {
          setErrors((data as any).errors);
          addToast({
            title: `Could not save settings`,
            kind: "danger",
          });
          // onError(data);
          window.logger(
            "%c[AccountJobBoardCustomDomain] updateCareersPage onerror",
            "background-color: #eb7f37",
            { data },
          );
        },
      },
    );
  };

  /* updateCareersPageState
  --===================================================-- */
  const updateCareersPageState = (name, value) => {
    props.setIsDirty(true);
    // settings = Object.assign({}, settings, { [name]: value });
    window.logger(
      "%c[AccountJobBoardCustomDomain] updateCareersPageState",
      "color: #1976D2",
      careersPage,
      name,
      value,
    );
    setCareersPage({ ...careersPage, [name]: value });
  };

  const hasPersistedCustomDomain = !!currentOrganization?.careersPages[0]?.customDomain;

  const bottomBarContent = !hasPersistedCustomDomain ? (
    <Button
      className="submit-button"
      size="small"
      styleType="primary"
      onClick={onClickSubmit}
      loading={isLoadingCreateCustomDomain}
    >
      Save domain
    </Button>
  ) : (
    <Button
      className="submit-button"
      size="small"
      styleType="primary"
      onClick={handleDeleteCustomDomain}
      loading={isLoadingDeleteCustomDomain}
    >
      Delete domain
    </Button>
  );

  const viewLink = (
    <Button
      type="externalLink"
      link="https://help.polymer.co/en/articles/10250419-configuring-a-custom-domain"
      styleType="text"
    >
      View docs
      <Icon name="external-link" />
    </Button>
  );

  return (
    <>
      <Helmet title="Job board custom domain" />

      <SettingsContainer
        title="Job board custom domain"
        description="Here's where you can specify the custom domain configured for your job board."
        actions={viewLink}
        bottomBar={bottomBarContent}
        fullWidthForm={true}
      >
        <form onSubmit={handleSubmit}>
          <FormSection>
            <FormInput
              name="customDomain"
              onChange={updateCareersPageState}
              pretext="https://"
              placeholder="jobs.company.com"
              label="Custom domain"
              className=""
              disabled
              value={removeHttpPrefix(customDomain) || ""}
              errors={errors}
              isDisabled={hasPersistedCustomDomain}
            />

            <CustomDomainStatus careersPage={careersPage} />
          </FormSection>
        </form>
      </SettingsContainer>
    </>
  );
}

export default AccountJobBoardCustomDomain;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Link = styled(Link)((props) => {
  const t: any = props.theme;
  return css`
    label: AccountJobBoardCustomDomain_Link;
    ${[t.text.medium]};
    &:hover {
      text-decoration: underline;
    }
  `;
});
