import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import find from "lodash/find";
import findKey from "lodash/findKey";
import isEmpty from "lodash/isEmpty";
import { useQueryClient } from "react-query";

import SettingsContainer from "@ats/src/components/shared/SettingsContainer";
import UnsavedChangesGuard from "@ats/src/components/shared/UnsavedChangesGuard";
import Icon from "@ats/src/components/shared/Icon";
import FormSection from "@ats/src/components/forms/FormSection";
import FormLabel from "@ats/src/components/forms/FormLabel";
import Button from "@ats/src/components/shared/Button";
import FormSelect from "@ats/src/components/forms/FormSelect";
import FormFieldMapper from "@ats/src/components/forms/FormFieldMapper";
import ConfirmationModal from "@ats/src/components/modals/ConfirmationModal";
import OauthConnectButton from "./OuathConnectButton";
import OauthReconnectModal from "@ats/src/components/modals/OauthReconnectModal";
import { useFeatureFlipper, Features } from "@ats/src/components/shared/FeatureFlipper";

import { webflowJobFieldMappingOptions } from "@ats/src/lib/lookups";

import { useDeleteOauthAuthentication } from "@shared/queryHooks/useOauth";
import {
  useWebflowSites,
  useWebflowCollections,
  useWebflowCollection,
  useCreateWebflowIntegration,
  useUpdateSelectedSite,
  useUpdateSelectedCollection,
  useUpdateFieldMapping,
} from "@shared/queryHooks/useWebflow";

import { useModalContext } from "@shared/context/ModalContext";
import { useToastContext } from "@shared/context/ToastContext";
import { useCurrentSession } from "@ats/src/context/CurrentSessionContext";

import { isDeadlineDateAfterCurrent } from "@shared/lib/time";

function AccountIntegrationsWebflow(props) {
  const { openModal, removeModal } = useModalContext();
  const addToast = useToastContext();
  const queryCache = useQueryClient();

  const { currentOrganization } = useCurrentSession();
  const { hasWebflowAuthentication, webflowIntegration, webflowAuthenticatedViaApiV2 } = currentOrganization;
  const webflowUpdatesInfoModalEnabled = useFeatureFlipper()({ feature: Features.WEBFLOW_UPDATE_ALERTS });
  const hasWebflowIntegration = webflowIntegration != undefined;
  const hasWebflowSite = hasWebflowIntegration && webflowIntegration.siteId != undefined;
  const hasWebflowCollection =
    hasWebflowIntegration && webflowIntegration.collectionId != undefined;
  const hasWebflowFieldMapping =
    hasWebflowIntegration && webflowIntegration.fieldMapping != undefined;
  const needsFieldMapping =
    hasWebflowSite && hasWebflowCollection && isEmpty(webflowIntegration.fieldMapping);

  const [isDirty, setIsDirty] = React.useState(false);

  const {
    mutate: deleteOauthAuthentication,
    isLoading: isLoadingDeleteOauthAuthentication,
  } = useDeleteOauthAuthentication();

  const {
    data: webflowSitesData,
    isLoading: isLoadingWebflowSites,
    error: sitesError,
    status: sitesStatus,
  } = useWebflowSites({
    enabled: hasWebflowAuthentication,
    onError: handleWebflowSitesError
  });
  const {
    data: webflowCollectionsData,
    isLoading: isLoadingWebflowCollections,
    isFetching: isFetchingWebflowCollections,
    error: collectionsError,
    status: collectionsStatus,
  } = useWebflowCollections({
    enabled: hasWebflowAuthentication && hasWebflowSite,
  });
  // const webflowCollectionsData = null;
  const {
    data: webflowCollectionData,
    isLoading: isLoadingWebflowCollection,
  } = useWebflowCollection({
    collectionId: webflowIntegration?.collectionId,
  });
  const { mutate: createWebflowIntegration } = useCreateWebflowIntegration();
  const {
    mutate: updateSelectedSite,
    isLoading: isLoadingUpdateSelectedSite,
  } = useUpdateSelectedSite();
  const {
    mutate: updateSelectedCollection,
    isLoading: isLoadingUpdateSelectedCollection,
  } = useUpdateSelectedCollection();
  const {
    mutate: updateFieldMapping,
    isLoading: isLoadingUpdateFieldMapping,
  } = useUpdateFieldMapping();

  const siteId = webflowIntegration?.siteId;
  const collectionId = webflowIntegration?.collectionId;
  const webflowSites = webflowSitesData || [];
  // const webflowCollections = webflowCollectionsData || [];
  const webflowCollection = webflowCollectionData || {};
  // const fieldMapping = hasWebflowFieldMapping && webflowIntegration.fieldMapping;
  const shouldUseWebflowApiV2 = webflowAuthenticatedViaApiV2 || false;

  const [webflowCollections, setWebflowCollections] = React.useState(webflowCollectionsData || []);
  const [fieldMapping, setFieldMapping] = React.useState(webflowIntegration?.fieldMapping);
  const [errors, setErrors] = React.useState(null);
  const [reconnectWebflowModalOpened, setReconnectWebflowModalOpened] = React.useState(false);

  const isConnectedToWebflow = hasWebflowAuthentication;

  React.useEffect(() => {
    setFieldMapping(webflowIntegration?.fieldMapping);
  }, [webflowIntegration]);

  React.useEffect(() => {
    setWebflowCollections(webflowCollectionsData || []);
  }, [webflowCollectionsData]);

  window.logger("%c[AccountIntegrationsWebflow] render", "color: #F996D2; background: #333333", {
    // ...state.context,
    // value: state.value,
    // state,
    siteId,
    collectionId,
    webflowSites,
    webflowCollections,
    webflowCollection,
    hasWebflowAuthentication,
    webflowIntegration,
    fieldMapping,
    reactQuery: {
      sitesError: sitesError?.response?.data?.errors?.general[0],
      sitesStatus,
      webflowSitesData,
      webflowCollectionsData,
    },
  });

  /* Handle Reconnect Webflow Modal
  --===================================================-- */
  const shouldOpenReconnectWebflowModal =
    webflowUpdatesInfoModalEnabled &&
    hasWebflowAuthentication &&
    !shouldUseWebflowApiV2 &&
    !reconnectWebflowModalOpened;

  const handleAuthenticationUpdate = React.useCallback(() => {
    const copyBeforeDeadline = (
      <React.Fragment>
        <p>You must <b>reauthenticate your Webflow account by March 17, 2025 or job data will stop syncing</b>.</p><br/>
        <p>Please reconnect to Webflow now or contact the appropriate member of your team. For questions, email support@polymer.co</p>
      </React.Fragment>
    );
    const copyAfterDeadline = (
      <React.Fragment>
        <p>Job data stopped syncing with Webflow because your account was not reauthenticated by March 17, 2025.</p><br />
        <p><b>Reconnect your Webflow account to resume syncing.</b> For questions, contact support@polymer.co</p>
      </React.Fragment>
    );
    const isAfterDeadline = isDeadlineDateAfterCurrent("03-17-2025");
    const subcopy = isAfterDeadline ? copyAfterDeadline : copyBeforeDeadline;

    const modal = (
      <OauthReconnectModal
        title="Reconnect Webflow"
        path={`/auth/webflow`}
        buttonLabel={"Reconnect to Webflow"}
        onCancel={removeModal}
        subcopy={subcopy}
      />
    );

    openModal(modal);
  }, [openModal, removeModal]);

  React.useEffect(() => {
    if (shouldOpenReconnectWebflowModal) {
      handleAuthenticationUpdate();
      setReconnectWebflowModalOpened(true);
    }
  }, [shouldOpenReconnectWebflowModal, handleAuthenticationUpdate]);

  /* Helpers
  --===================================================-- */

  const getNameValue = (object) => {
    return shouldUseWebflowApiV2 ? object?.displayName : object?.name;
  };

  const getRequiredValue = (object) => {
    return shouldUseWebflowApiV2 ? object?.isRequired : object?.required;
  };

  const findWebflowSite = (id) => {
    window.logger("%c[AIW] findWebflowSite", "color: #1976D2", { id, webflowSites });
    return find(webflowSites, (s) => s.id === id);
  };

  const webflowSiteOptions = () => {
    return webflowSites.map((site) => {
      return { value: site.id, label: getNameValue(site) };
    });
  };

  const webflowCollectionOptions = () => {
    return webflowCollections.map((collection) => {
      return { value: collection.id, label: getNameValue(collection) };
    });
  };

  const filteredWebflowFields = () => {
    if (webflowCollection.fields == undefined) {
      return [];
    }

    return webflowCollection.fields
      .filter(
        (field) =>
          (field.isEditable || field.editable) && // Only show Webflow fields that are editable
          field.slug !== "isArchived" && // We don't want this mapped in this UI
          field.slug !== "isDraft" && // We don't want this mapped in this UI
          field.slug !== "slug" && // We don't want this mapped in this UI
          field.slug !== "_archived" && // We don't want this mapped in this UI
          field.slug !== "_draft", // We don't want this mapped in this UI
      )
      .sort((a, b) => {
        const displayNameA = getNameValue(a);
        const displayNameB = getNameValue(b);
        if (displayNameA.toLowerCase() === "title" || displayNameA.toLowerCase() === "name") {
          return -1;
        } else if (
          displayNameA.toLowerCase() === "title" &&
          displayNameB.toLowerCase() === "description"
        ) {
          return 1;
        } else {
          return 0;
        }
      });
  };

  const filteredWebflowFieldsOptions = (type = "") => {
    const fields = filteredWebflowFields();

    return fields
      .filter(
        (field) => type === "" || field.type === type, // if no type is passed then don't filter by type
      )
      .map((field) => {
        return {
          value: field.id,
          label: getNameValue(field),
          type: field.type,
          isRequired: getRequiredValue(field),
        };
      });
  };

  const filteredPolymerFieldsOptions = (type = "", selectedValue) => {
    window.logger("%c[AIW] filteredPolymerFieldsOptions", "color: #1976D2", {
      type,
      selectedValue,
      fieldMapping,
    });
    const options = webflowJobFieldMappingOptions(shouldUseWebflowApiV2);
    return options
      .filter(
        (field) => type === "" || field.type === type, // if no type is passed then don't filter by type
      )
      .filter((field) => {
        // const { fieldMapping } = (state as any).context;
        // const usedPolymerFields = Object.keys(fieldMapping);
        // return fieldMapping[field.value] == undefined; // return false, so don't include this.
        return selectedValue === field.value || fieldMapping[field.value] == undefined;
      })
      .map((field) => {
        return { value: field.value, label: field.label, type: field.type };
      });
  };

  function handleWebflowSitesError(error) {
    const sitesError = error?.response?.data?.errors?.general[0] || "Unknown error fetching Webflow sites.  Please try disconnecting and reconnecting your Webflow account.";
    window.logger("%c[AIW] handleWebflowSitesError", "color: orange", {
      sitesError
    });
    addToast({ title: sitesError, kind: "warning", delay: 15000 });
  }

  const handleWebflowSiteSelection = (name, value) => {
    if (value === siteId) {
      return;
    }

    // const webflowSite = find(webflowSites, (s) => s.id === value);
    const webflowSite = findWebflowSite(value);
    const integrationData = {
      siteId: webflowSite.id,
      siteName: webflowSite.name,
      sitePreviewUrl: webflowSite.previewUrl,
      collectionId: null,
      collectionName: null,
      fieldMapping: {},
    };

    window.logger(
      "%c[AccountIntegrationWebflow] handleWebflowSiteSelection site",
      "color: #1976D2",
      {
        name,
        value,
        webflowSite,
        hasWebflowIntegration,
        integrationData,
      },
    );

    if (webflowSite == undefined) {
      addToast({ title: "Could not find that site", kind: "warning" });
      return;
    }

    if (hasWebflowIntegration) {
      updateSelectedSite(
        {
          webflowIntegrationId: "not_currently_used_as_controller_defaults_to_current_integration",
          ...integrationData,
        },
        {
          onSuccess: (data) => {
            addToast({ title: "Connected Site", kind: "success" });
          },
          onError: (response: any) => {
            addToast({ title: "Failed connecting Site", kind: "warning" });
          },
        },
      );
    } else {
      createWebflowIntegration(
        {
          ...integrationData,
        },
        {
          onSuccess: (data) => {
            addToast({ title: "Connected Site", kind: "success" });
          },
          onError: (response: any) => {
            addToast({ title: "Failed connecting Site", kind: "warning" });
          },
        },
      );
    }
  };

  const handleWebflowCollectionSelection = (name, value) => {
    const webflowCollection = find(webflowCollections, (s) => s.id === value);
    const integrationData = {
      collectionId: webflowCollection.id,
      collectionName: getNameValue(webflowCollection),
    };

    window.logger(
      "%c[AccountIntegrationWebflow] handleWebflowCollectionSelection site",
      "color: #1976D2",
      {
        name,
        value,
        webflowCollection,
      },
    );

    if (webflowCollection == undefined) {
      addToast({ title: "Could not find that collection", kind: "warning" });
      return;
    }

    updateSelectedCollection(
      {
        webflowIntegrationId: "not_currently_used_as_controller_defaults_to_current_integration",
        ...integrationData,
      },
      {
        onSuccess: (data) => {
          addToast({ title: "Collection saved", kind: "success" });
        },
        onError: (response: any) => {
          addToast({ title: "Failed saving Collection", kind: "warning" });
        },
      },
    );
  };

  const handleWebflowFieldSelection = (selection) => {
    setErrors(null);
    // window.logger(
    //   "%c[AccountIntegrationWebflow] handleWebflowFieldSelection",
    //   "color: #1976D2; font-size: 0.75rem;",
    //   {
    //     selection,
    //   },
    // );

    const { name: originFieldValue, value: mappedFieldValue, selectedValue } = selection;

    const webflowField = find(webflowCollection.fields, (field) => field.id === originFieldValue);

    let mapping = { [mappedFieldValue]: webflowField };

    if (selectedValue != undefined) {
      mapping = { [selectedValue]: null, ...mapping }; // This overwrites the null value with the current value if they are the same or sets the current to null if it is being replaced
    }

    // Since we are using a somewhat backwards fieldMapping object to look up a mapping, this allows us to clear the field (had to do this because keys were getting snakecased then camelcased and jacking up the keys)
    if (mappedFieldValue == undefined) {
      mapping = { [selectedValue]: null };
    }

    setFieldMapping({ ...fieldMapping, ...mapping });

    window.logger("%c[AccountIntegrationWebflow] handleWebflowFieldSelection", "color: #1976D2", {
      selection,
      originFieldValue,
      mappedFieldValue,
      selectedValue,
      fieldMapping,
      mapping,
    });
  };

  const handleOnClickSaveMapping = () => {
    const requiredWebflowIds = filteredWebflowFields()
      .filter((field) => getRequiredValue(field))
      .map((field) => field.id);

    const usedWebflowIds = Object.values(fieldMapping)
      .filter((field) => field && getRequiredValue(field))
      .map((field) => (field as any).id);

    const missingWebflowIds = requiredWebflowIds.filter((id) => !usedWebflowIds.includes(id));

    window.logger("%c[AIW] handleOnClickSaveMapping", "color: #1976D2", {
      requiredWebflowIds,
      usedWebflowIds,
      missingWebflowIds,
    });

    if (missingWebflowIds.length === 0) {
      updateFieldMapping(
        {
          webflowIntegrationId: "not_currently_used_as_controller_defaults_to_current_integration",
          fieldMapping,
        },
        {
          onSuccess: (data) => {
            addToast({ title: "Syncing with Webflow", kind: "success" });
          },
          onError: (response: any) => {
            addToast({ title: "Failed saving mapping", kind: "warning" });
          },
        },
      );
    } else {
      let errors = {};

      missingWebflowIds.forEach((id) => {
        errors[id] = ["is required"];
      });

      addToast({ title: "Please map required fields", kind: "error" });
      setErrors(errors);
    }
  };

  /* handleOnClickDisconnectWebflow
  --===================================================-- */
  const handleOnClickDisconnectWebflow = () => {
    const modal = (
      <ConfirmationModal
        title="Are you sure you want to disconnect?"
        subcopy="Jobs will no longer be synced with your Webflow CMS."
        confirmText="Disconnect"
        isDestructiveConfirm={true}
        cancelText="Cancel"
        onConfirm={() => {
          removeModal();
          deleteOauthAuthentication(
            { provider: "webflow" },
            {
              onSuccess: () => {
                // TODO ?
              },
            },
          );
        }}
        onCancel={removeModal}
      />
    );

    openModal(modal);
  };

  const ViewLink = (
    <>
      <Button
        type="externalLink"
        link="https://polymer.co/blog/use-webflow-cms-to-display-your-wrk-job-posts-on-your-webflow-site"
        styleType="text"
      >
        View docs
        <Icon name="external-link" />
      </Button>
      {isConnectedToWebflow ? (
        <Button
          type="externalLink"
          link="https://webflow.com/dashboard?rfsn=4595939.f09147&utm_medium=affiliate"
          styleType="text"
        >
          Visit Webflow dashboard
          <Icon name="external-link" />
        </Button>
      ) : (
        <Button
          type="externalLink"
          link="https://webflow.com/?rfsn=4595939.f09147&utm_medium=affiliate"
          styleType="text"
        >
          Visit Webflow
          <Icon name="external-link" />
        </Button>
      )}
    </>
  );

  const fieldNodes = () => {
    const options = filteredWebflowFieldsOptions();

    return options.map((option) => {
      const selectedMapping = findKey(fieldMapping, (field) => field && field.id === option.value);
      const selectedValue = selectedMapping ? selectedMapping : null;

      return (
        <FormFieldMapper
          key={option.value}
          field={option.value}
          label={option.label}
          mappingOptions={filteredPolymerFieldsOptions(option.type, selectedValue)}
          selectedValue={selectedValue}
          onSelect={handleWebflowFieldSelection}
          isRequired={option.isRequired}
          errors={errors}
        />
      );
    });
  };

  const BottomBar = (
    <>
      <UnsavedChangesGuard
        hasUnsavedChanges={isDirty}
        navigate={(pathname) => {
          window.logger(
            "%c[AccountIntegrationsWebflow] UnsavedChangesGuard navigate",
            "color: #1976D2",
            pathname,
          );
          setIsDirty(false);
          props.history.push(pathname);
        }}
      />

      <Button
        className="submit-button"
        type="button"
        size="small"
        onClick={handleOnClickSaveMapping}
        loading={isLoadingUpdateFieldMapping}
        aria-label="Save field mapping"
      >
        Save field mapping
      </Button>
    </>
  );

  return (
    <SettingsContainer
      title="Webflow"
      description="Build your own custom job board in Webflow and populate it with jobs created in Polymer. An active Webflow CMS plan is required."
      actions={ViewLink}
      bottomBar={isConnectedToWebflow && hasWebflowSite && hasWebflowCollection ? BottomBar : null}
      fullWidthForm={true}
    >
      {!isConnectedToWebflow && (
        <OauthConnectButton
          path={`/auth/webflow`}
          // path={`/auth/webflow?current_organization_id=${currentOrganization.id}`}
          label="Connect to Webflow"
        />
      )}

      {isConnectedToWebflow && (
        <>
          <FormSection title="CMS configuration">
            <FormSelect
              onChange={handleWebflowSiteSelection}
              name="siteId"
              label="Website"
              description="Select the Webflow Site to connect to Polymer"
              className=""
              value={siteId}
              options={webflowSiteOptions()}
              errors={null}
            />

            {hasWebflowSite ? (
              isFetchingWebflowCollections ? (
                <Styled.Loading>Loading...</Styled.Loading>
              ) : (
                <>
                  {webflowCollections.length === 0 && (
                    <Styled.Requirements>
                      <FormLabel label="Site requirements" />
                      <Styled.RequirementInstructions>
                        The selected Webflow site does not meet the following requirements.
                      </Styled.RequirementInstructions>
                      <div>
                        {webflowCollections.length === 0 && (
                          <Styled.Requirement>
                            <Styled.RequirementTitle>
                              <Icon name="alert-triangle" />
                              You must have a CMS collection created to use for Polymer jobs.
                            </Styled.RequirementTitle>
                            <Button
                              type="externalLink"
                              link="https://university.webflow.com/article/cms-collections"
                              styleType="text"
                            >
                              Learn more
                            </Button>
                          </Styled.Requirement>
                        )}
                      </div>
                    </Styled.Requirements>
                  )}

                  {webflowCollections.length > 0 && (
                    <FormSelect
                      onChange={handleWebflowCollectionSelection}
                      name="collectionId"
                      label={`Collection`}
                      description="Select the Webflow CMS collection to use for Polymer jobs"
                      className=""
                      value={collectionId}
                      options={webflowCollectionOptions()}
                      errors={null}
                    />
                  )}
                </>
              )
            ) : null}
          </FormSection>

          {hasWebflowCollection && webflowCollections.length > 0 && (
            <FormSection title="Collection field mapping">
              {isLoadingWebflowCollection ? (
                <div>Loading...</div>
              ) : (
                <div>
                  <Styled.FieldsHeader>
                    <div>Field in Webflow</div>
                    <div>Value from Polymer</div>
                  </Styled.FieldsHeader>
                  <Styled.Fields>{fieldNodes()}</Styled.Fields>
                </div>
              )}
            </FormSection>
          )}

          <Styled.Disconnect styleType="secondary" onClick={handleOnClickDisconnectWebflow}>
            Disconnect Webflow
          </Styled.Disconnect>
        </>
      )}
    </SettingsContainer>
  );
}

export default AccountIntegrationsWebflow;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Requirements = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsWebflow_Requirements;
    ${[t.mb(5)]}
    display: flex;
    flex-direction: column;
  `;
});

Styled.RequirementInstructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsWebflow_Requirements;
    ${[t.text.xs, t.mt(-1), t.mb(2)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    line-height: 1.4;
    max-width: 32rem;
  `;
});

Styled.Requirement = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsWebflow_Requirement;
    ${[t.px(4)]}
    min-height: ${t.spacing[12]};
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : "transparent"};
    border-top: 1px solid ${t.dark ? t.color.gray[700] : "transparent"};
    border-top: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-of-type {
      border-top: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
      border-top-left-radius: 0.4375rem;
      border-top-right-radius: 0.4375rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 0.4375rem;
      border-bottom-right-radius: 0.4375rem;
    }
  `;
});

Styled.RequirementTitle = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsWebflow_RequirementTitle;
    ${[t.my(2), t.mr(4)]};
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
    display: flex;
    align-items: center;
    line-height: 1.4;

    svg {
      ${[t.mr(2)]};
      color: ${t.dark ? t.color.red[500] : t.color.red[600]};
      flex-shrink: 0;
    }
  `;
});

Styled.FieldsHeader = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsWebflow_FieldsHeader;
    ${[t.pl(0), t.pr(2), t.text.xs, t.text.bold, t.mb(2)]}
    color: ${t.dark ? t.color.gray[300] : t.color.black};
    display: flex;
    div {
      flex: 1;
      &:last-of-type {
        ${t.pl(4)}
      }
    }
  `;
});

Styled.Fields = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsWebflow_Fields;
    ${[t.rounded.md]}
    border: 1px solid ${t.dark ? t.color.gray[800] : t.color.gray[200]};
    background-color: ${t.dark ? t.color.gray[800] : t.color.white};
  `;
});

Styled.Disconnect = styled(Button)((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsWebflow_Disconnect;
    ${[t.mb(8)]};
  `;
});

Styled.Loading = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AccountIntegrationsWebflow_Loading;
    color: ${t.dark ? t.color.gray[400] : t.color.gray[600]};
  `;
});
