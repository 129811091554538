import React, { ReactFragment } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import CenterModal from "@ats/src/components/modals/CenterModal";
import Button from "@ats/src/components/shared/Button";

import { useModalContext } from "@shared/context/ModalContext";

type Props = {
  title: string;
  subcopy?: string | ReactFragment;
  onConfirm: ({ }) => void;
  onCancel: ({ }) => void;
  confirmText: string
};

function AlertModal(props: Props) {
  const { title, subcopy, onConfirm, onCancel, confirmText } = props;

  // const { modal, dismissModal, callback, setCallback, isVisible } = useModalContext();
  const { modal, dismissModalWithAnimation } = useModalContext();

  const handleOnCancel = (event) => {
    if (modal) {
      dismissModalWithAnimation(() => onCancel);
    } else {
      onCancel(event);
    }
  };

  const handleOnConfirm = (event) => {
    if (modal) {
      dismissModalWithAnimation(() => onConfirm);
    } else {
      onConfirm(event);
    }
  };

  return (
    <CenterModal headerTitleText={title} onCancel={handleOnCancel}>
      {subcopy && <Styled.Instructions>{subcopy}</Styled.Instructions>}
      <Styled.ButtonContainer>
        <Button onClick={handleOnConfirm}>{confirmText}</Button>
      </Styled.ButtonContainer>
    </CenterModal>
  );
}

export default AlertModal;

/* Styled Components
======================================================= */
let Styled: any;
Styled = {};

Styled.Instructions = styled.p((props) => {
  const t: any = props.theme;
  return css`
    label: AlertModal_Instructions;
    ${[t.mb(5)]}
  `;
});

Styled.ButtonContainer = styled.div((props) => {
  const t: any = props.theme;
  return css`
    label: AlertModal_ButtonContainer;
    display: flex;
    > * {
      ${t.mr(3)}

      &:last-of-type {
        ${t.mr(0)}
      }
    }
  `;
});
