import React from "react";
import { useWebsocket } from "@ats/src/websockets/WebsocketContext";
import { useQueryClient } from "react-query";

import { useToastContext } from "@shared/context/ToastContext";
import { useGlobalChannelContext } from "@ats/src/context/GlobalChannelContext";

function WebsocketGlobalChannelHandler(props) {
  const Websocket = useWebsocket();
  const queryCache = useQueryClient();

  const addToast = useToastContext();
  const { enableUpdateAvailable, enabledMaintenanceComplete } = useGlobalChannelContext();

  React.useEffect(() => {
    const subscription = setupWebsocketConnections();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const setupWebsocketConnections = () => {
    // window.logger(
    //   "%c[WebsocketGlobalChannelHandler] setupWebsocketConnections",
    //   "background: #334F30; color: #E4FF09",
    // );

    const subscription = Websocket.subscriptions.create(
      { channel: "GlobalChannel" },
      {
        connected: function () {
          // window.logger(
          //   "%c[WebsocketGlobalChannelHandler] WEBSOCKET GlobalChannel ---- Connected",
          //   "background: #003423; color: #E4FF09",
          // );
        },

        disconnected: function () {
          // window.logger(
          //   "%c[WebsocketGlobalChannelHandler] WEBSOCKET GlobalChannel ---- Disconnected",
          //   "background: #EF3423; color: #E4FF09",
          // );
        },

        received: function (data) {
          // window.logger(
          //   "%c[WebsocketGlobalChannelHandler] WEBSOCKET GlobalChannel ---- Received",
          //   "background: #003423; color: #E4FF09",
          //   data,
          // );

          handleGlobalMessage(data);
        },
      },
    );
    return subscription
  };

  function handleGlobalMessage(data) {
    if (data.action != null) {
      // const [actionGroup, actionName] = data.action.split(".");

      switch (data.action) {
        case "showGrowl":
          // window.logger(
          //   "%c[WebsocketGlobalChannelHandler] === WEBSOCKET GlobalChannel ++ showGrowl",
          //   "background-color: #19d232; color: white;",
          //   { payload: data.payload },
          // );
          addToast(data.payload);
          break;

        case "fetchOrganization":
          queryCache.invalidateQueries([
            "currentOrganization",
            Number(data.payload.organizationId),
          ]);
          break;

        case "downloadCandidatesCsvExport":
          addToast({
            title: "CSV export is ready",
            kind: "success",
            delay: 10000,
            externalLink: { href: data.payload.candidatesCsvExportUrl, text: "Download" },
          });
          break;

        case "importJobCandidatesComplete":
          addToast({
            title: "Candidate import complete",
            kind: "success",
          });
          queryCache.invalidateQueries(["jobs", data.payload.jobId]);
          queryCache.invalidateQueries("jobApplicationsForStage");
          break;
        case "attachExternalResumeComplete":
          queryCache.invalidateQueries(["jobApplication", Number(data.payload.jobApplicationId)]);
          break;
        case "ACTIVITIES_DISMISSED":
          addToast({
            title: "Notifications dismissed",
            kind: "success",
            // delay: 10000,
          });

          queryCache.invalidateQueries("infiniteNotifications");
          queryCache.invalidateQueries("notificationsHasUnread");
          queryCache.invalidateQueries("notificationsSummaryCounts");

          break;
        case "ACTIVITIES_MARKED_READ":
          addToast({
            title: "Notifications marked as read",
            kind: "success",
            // delay: 10000,
          });

          queryCache.invalidateQueries("infiniteNotifications");
          queryCache.invalidateQueries("notificationsHasUnread");
          break;
        case "WEBFLOW_COLLECTION_NOT_FOUND":
          addToast({
            title:
              "Could not find the collection on your Webflow site; please make sure you have published your site with this collection",
            kind: "warning",
            delay: 15000,
          });

          queryCache.invalidateQueries("currentOrganization");
          queryCache.invalidateQueries("webflowSites");
          queryCache.invalidateQueries("webflowCollections");
          queryCache.invalidateQueries("webflowCollection");
          break;
        case "WEBFLOW_INTEGRATION_NEEDS_AUTHENTICATION":
          addToast({
            title:
              "Webflow sync is currently disabled.  An Admin user of your organization must reconnect the Webflow integration.",
            kind: "warning",
            delay: 20000,
          });

          queryCache.invalidateQueries("currentOrganization");
          queryCache.invalidateQueries("webflowSites");
          queryCache.invalidateQueries("webflowCollections");
          queryCache.invalidateQueries("webflowCollection");
          break;

        case "UPDATE_AVAILABLE":
          enableUpdateAvailable();
          break;

        case "MAINTENANCE_COMPLETE":
          enabledMaintenanceComplete();
          break;

        default:
          break;
      }
    }
  }

  return <>{props.children}</>;
}

export default WebsocketGlobalChannelHandler;
